/** @format */
import React, { useEffect, useRef, useState } from "react";

import { openGallery } from "./../extension/openGallery";

import "./../style/edit-dialog.css";
import { food_icon, profile_icon } from "../extension/exportedIcons";
import { userAuthenticated } from "../extension/currentUser";
import {
  changeBio,
  changeProfile,
  deleteItem,
  deleteItemDigital,
  url_path,
} from "../extension/exportedStrings";
import { formatDate } from "../extension/timestampToDate";
import CartImage from "../extension/setCartIcon";
import EditImage from "../extension/setEditItemIcon";

const EditProfileDialog = ({
  isOpen,
  setIsOpen,
  profilePic,
  setProfilePic,
  items,
  setItems,
}) => {
  const divRef = useRef(null);

  const [bio, setBio] = useState("");

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (divRef.current && !divRef.current.contains(event.target)) {
        closeDialog();
      }
    };

    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        closeDialog();
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen]);

  const closeDialog = () => {
    setIsOpen(false);
    document.body.style.overflowY = "auto";
  };

  const changeProfilePic = async (imageUrl) => {
    const url = url_path + changeProfile;
    const data = {
      type: "profile",
      name: userAuthenticated,
      image: imageUrl, // Replace with the actual username
    };

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const changeFoodIconPic = async (imageUrl) => {
    const url = url_path + changeProfile;
    const data = {
      type: "food",
      name: userAuthenticated,
      image: imageUrl, // Replace with the actual username
    };

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateBio = async () => {
    const url = url_path + changeBio;
    const data = {
      username: userAuthenticated,
      bio: bio, // Replace with the actual username
    };

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });


  };

  const deleteProfileItem = async (itemUrl) => {
    const url = url_path + deleteItem;
    const data = {
      username: userAuthenticated,
      id: itemUrl, // Replace with the actual username
    };

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
          console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };


  const deleteProfileDigital = async (itemUrl) => {
    const url = url_path + deleteItemDigital;
    const data = {
      username: userAuthenticated,
      id: itemUrl, // Replace with the actual username
    };

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {

      })
      .catch((error) => {
        console.log(error);
      });
  };


  return (
    <div>
      <div className={isOpen ? "darkenDiv" : "hidden"}>
        <div className={isOpen ? "editDialogDiv" : "hidden"} ref={divRef}>
          <div className="editIconDiv">
            <div>
              <img
                src={profilePic}
                className="editProfilePic"
                id="profilePic"
              />
              <p
                className="editProfileLabel"
                onClick={() => {
                  openGallery()
                    .then((file) => {
                      const reader = new FileReader();
                      reader.readAsDataURL(file);
                      reader.onload = () => {
                        const imageDataUrl = reader.result;
                        // Call the handler function with the image data URL
                        setProfilePic(imageDataUrl);
                        changeProfilePic(imageDataUrl);
                      };
                    })
                    .catch((error) => {
                      console.error("Error:", error);
                    });
                }}
              >
                Change Profile Pic
              </p>
            </div>

            <div>
              <img src={food_icon} className="editProfilePic" id="foodPic" />
              <p className="editProfileLabel2">Change Shop Pic</p>
            </div>
          </div>
          <p className="editUpdateBio">Update Bio</p>
          <textarea
            className="editInput"
            placeholder="Share your thoughts..."
            onChange={(e) => {
              const bioText = e.target.value;
              setBio(bioText);
            }}
          ></textarea>
          <p className="editUpdateItems">Update Items</p>
          <div className="editProfileListDiv">
          <table className="orderTable">
              <tbody>
                {items.length > 0 ? (
                  items.map((item, index) => (
                    <tr key={index}>
                      <div className="editProfileListItemDiv">
                        <div className="editItemsLeftContainer">
                          <EditImage
                            timestring={item.timestring}
                            currentPath={userAuthenticated}
                            type={item.type}
                          />

                          <div className="editItemLeftTitle">
                            <p className="editItemName">{item.itemName}</p>
                          </div>
                        </div>
                        <div className="editItemsRightContainer">
                        <button
                            className="editItemUpdate"
                            onClick={() => {
                              if(item.type === "item"){
                                deleteProfileItem(item.itemUrl);
                              }else{
                                deleteProfileDigital(item.itemUrl);
                              }
                              setItems((prevItems) => prevItems.filter((it) => it.itemUrl !== item.itemUrl));
                            }}
                          > 
                           Delete
                          </button>
                        </div>
                      </div>
                    </tr>
                  ))
                ) : (
                  <div className="choiceErrorDiv">
                    <p className="choiceErrorLabel">No current items</p>
                  </div>
                )}
              </tbody>
            </table>
          </div>
          <button className="editButtonConfirm" 
          onClick={() => {
            if(bio.trim != ""){
              updateBio();
            }
            
            closeDialog();
          }}>Confirm</button>
        </div>
      </div>
    </div>
  );
};

export default EditProfileDialog;
