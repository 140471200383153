import React from 'react';

const ItemDropdown = ({ cName, selected, setSelected, setSelectedValue, array }) => {
  
  const handleQuantityChange = (event) => {
    let arrayIndex = parseInt(event.target.value) - 1;
    setSelected(parseInt(event.target.value));
    setSelectedValue(array[arrayIndex]);
  };

  return (
    <select
      className={cName}
      value={selected}
      onChange={handleQuantityChange}
    >
      {array.map((item, index) => (
        <option key={index} value={index + 1}>
          {item}
        </option>
      ))}
    </select>
  );
};

export default ItemDropdown;
