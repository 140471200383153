import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";

import MinibarComponent from "./Minibar.js";

import "./../style/loading-completion.css";
import { purchased, soci_coin } from '../extension/exportedIcons.js';
import { decryptData } from '../extension/dataEncryption.js';
import { purchase_key } from '../extension/exportedStrings.js';

import { purchaseSociCoin } from "../data/purchaseSociCoin";
import { purchaseValidity } from '../data/purchaseValidity.js';
import { addListToNotificationAndClearCart, insertBuyToNotification, updateBuyOrderStatus, updateOrderStatus } from '../data/reusuableBuyData.js';

function LoadingCompletion() {
  const navigate = useNavigate();
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState(location.pathname.slice(1));

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const encryptedData = urlParams.get("data");

    if (encryptedData) {
      try {
        const decryptedData = decryptData(encryptedData, purchase_key);

        purchaseValidity(decryptedData.unique_id)
        .then((isValid) => {
          if (isValid) {
            if (decryptedData.type === "socicoin") {
              purchaseSociCoin(decryptedData.price).then(() => {
                let path = `/completion`;
                navigate(path);
              });
            }else if(decryptedData.type === "checkout_buy"){
              updateBuyOrderStatus(decryptedData.unique_id)
                .then(() => {
                  return insertBuyToNotification(decryptedData.orders);
                })
                .then(() => {
                  let path = `/completion`;
                  navigate(path); // Assuming `navigate` is from React Router's `useNavigate`
                });
            }else if(decryptedData.type === "checkout_digital"){
              updateBuyOrderStatus(decryptedData.orders[0].itemUrl)
              .then(() => {
                return insertBuyToNotification(decryptedData.orders);
              })
              .then(() => {
                let path = `/completion`;
                navigate(path); // Assuming `navigate` is from React Router's `useNavigate`
              });
            }else if(decryptedData.type === "checkout_order"){
              updateOrderStatus(decryptedData.unique_id) .then(() => {
                return addListToNotificationAndClearCart(decryptedData.selectedCart);;
              })
              .then(() => {
                let path = `/completion`;
                navigate(path); // Assuming `navigate` is from React Router's `useNavigate`
              });
            }
          } 
        });
      } catch (error) {
          let path = `/`;
          navigate(path);
      }
    }
  }, [location]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // Trigger the dialog to confirm before navigating away
      event.preventDefault();
      event.returnValue = ""; // This ensures the browser shows the default warning dialog
    };

    // Attach the event listener
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      // Cleanup the event listener
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <div>
      <div className='loading-completion-div'>
        <div className="spinner-container">
          <div className="spinner"></div>
        </div>
      </div>
    </div>
    
  );
}

export default LoadingCompletion;
