import React, { useEffect, useState } from "react";

import { useNavigate, useLocation } from "react-router-dom";

import MinibarComponent from "./Minibar.js";
import FooterComponent from "./Footer.js";
import { displayCategoryArray } from "../data/categoryArray.js";
import {
  app_icon,
  food_icon,
  profile_icon,
  soci_coin,
} from "../extension/exportedIcons.js";
import {
  copyrightLabel,
  retrieveAllitemsAndDigitals,
  retrieveCategory,
  url_link,
  url_path,
} from "../extension/exportedStrings.js";
import HomeImage from "../extension/setHomeItemicon.js";

import "./../style/category_item.css";
import CategoryItemImage from "../extension/setCategoryItemIcon.js";
import { generateStars } from "../extension/generateStars.js";

function CategoryItem() {
  const navigate = useNavigate();
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState(() => {
    const path = location.pathname.split('/').pop();
    return path.replace(/and/g, ' and ');
  });
  
  const [items, setItems] = useState([]);

  const [isLoading, setIsLoading] = useState([true]);
  
  useEffect(() => {
    document.title = "SociRank - Store"; // Change the title here

    
    retrieveCategoryItems();
  }, []);

  const retrieveCategoryItems = () => {

    const url = url_path + retrieveCategory;
    console.log(currentPath);
    const data = {
      category: currentPath, // Replace with the actual username
    };
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        setItems(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error retrieving items:", error);
      });
  };

  const goCategory = (item) => {
    const path = `/category/${item.replace(/\s+/g, "")}`;
    window.location.href = path; // Reloads the page
  };

  const goToItem = (item) => {
    if(item.type === "item"){
      const path = `/item/${item.itemUrl}`;
      navigate(path);
    }else{
      const path = `/digital/${item.itemUrl}`;
      navigate(path);
    }
  }

  return (
    <div>
      <MinibarComponent />
      <div className="homeMenuDiv">
        {displayCategoryArray.map((item, index) => (
          <div className="homeMenuListDiv" onClick={() => goCategory(item)}>
            <p className="homeMenuText">{item}</p>
          </div>
        ))}
      </div>

      {isLoading ? (
        <div className="spinner-container-page">
          <div className="spinner"></div>
        </div>
      ) : (
      <div className="cash-container" id="cashContainer">
        {items.map((array, index) => (
          <div className="categoryItemTable" onClick={() => goToItem(array)}>
            <CategoryItemImage
              timestring={array.timestring}
              currentPath={array.username}
              type={array.type}
            />
            <div className="cashView"></div>

            <div className="categoryDivLabelSpacing">
              <div className="priceDiv">
                <img
                  src={`${url_link}images/${array.username}.png`}
                  onError={(e) => {
                    if (e.target.src !== food_icon) {
                      e.target.onerror = null;
                      e.target.src = food_icon;
                    }
                  }}
                  className="coinImg2"
                  width="30"
                  height="30"
                />
                <p className="categoryItemLabelName">{array.username}</p>
              </div>
              <p className="categoryStarsLabel"> {generateStars(array.itemRating)}</p>
            </div>
          </div>
        ))}
      </div>
      )}
      <div className="homeFooterDiv">
        <div className="footerView">
          <img className="footerImg" src={app_icon} alt="Image" />
          <FooterComponent />
        </div>

        <p className="copyrightLabel2">{copyrightLabel}</p>
      </div>
      
    </div>
  );
}

export default CategoryItem;
