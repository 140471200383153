/** @format */
import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import "./../style/login.css";
import "./../style/footer.css";

import CryptoJS from "crypto-js";

import {
  app_icon,
  app_icon2,
  apple_icon,
  google_icon,
  google_icon2,
} from "./../extension/exportedIcons";

import {
  copyrightLabel,
  googleClientID,
  googleLoginSecretKey,
  login,
  url_path,
} from "./../extension/exportedStrings";

import FooterComponent from "./../design/Footer.js";
import { emailCheck } from "../data/checkEmail.js";
import { addDetailFunction } from "../firebase/detailCheckFirebase.js";

const LoginMobile = () => {
  const navigate = useNavigate();

  const [button, setButton] = useState("Log in");
  const [isButtonDisabled, setButtonDisabled] = useState(false);

  useEffect(() => {
    document.title = "SociRank - Login"; // Change the title here

    window.gapi.load("client:auth2", () => {
      window.gapi.client.init({
        clientId: googleClientID,
        scope: "profile email", // Specify the scopes you need
      });
    });

    addDetailFunction("website", "visited", "mobile web was visited");
  }, []);


  const goForgot = () => {
    let path = `/forgot-password`;
    navigate(path);
  };

  const goRegister = () => {
    let path = `/register`;
    navigate(path);
  };

  const handleSubmit = async () => {
      setButton("Processing...")
      const username = document.getElementById("username").value.trim();
      const password = document.getElementById("password").value;
  
      setButtonDisabled(true);
  
      const url = url_path + login;
      const data = {
        username: username, // Replace with the actual username
        password: password, // Replace with the actual password
      };
  
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error(`HTTP error! Status: ${res.status}`);
          }
          return res.json();
        })
        .then((data) => {
          // Handle the response data here
          if (data == "success") {
            // Assuming you have received authentication data like a token or username
            const authenticationData = {
              username: username,
            };
  
            // Save the data to localStorage
            localStorage.setItem(
              "currentUser",
              JSON.stringify(authenticationData)
            );
  
            if (window.location.pathname === '/login') {
              window.location.href = '/';
            }else{
              window.location.reload();
              window.history.replaceState({}, '', '/login');
            }
          } else {
            // do sumn about it later
            const secretKey = googleLoginSecretKey;
  
            // Encrypt the data with AES encryption
            const encryptedData = CryptoJS.AES.encrypt(data, secretKey).toString();
    
            const base64EncodedData = btoa(encryptedData);
  
            let path = `/login/${base64EncodedData}`;
            navigate(path);
          }
        })
        .catch((error) => {
          setButton("Server down")
          // Handle any errors that occurred during the fetch
          console.error("Fetch error:", error);
        });
    };

  const signInWithGoogle = async () => {
    try {
      const auth2 = window.gapi.auth2.getAuthInstance();
      await auth2.signIn();

      // Handle the signed-in user here
      const email = auth2.currentUser.get().getBasicProfile().getEmail();
      const result = await emailCheck(email);
      if (result == "success") {
        const fullname = auth2.currentUser.get().getBasicProfile().getName();
        const data = `${email} ${fullname}`;

        const secretKey = googleLoginSecretKey;

        // Encrypt the data with AES encryption
        const encryptedData = CryptoJS.AES.encrypt(data, secretKey).toString();

        const base64EncodedData = btoa(encryptedData);

        let path = `/create-account/${base64EncodedData}`;
        navigate(path);
      } else {
        // let path = `/register`;
        // navigate(path);
        const authenticationData = {
          username: result,
        };

        // Save the data to localStorage
        localStorage.setItem("currentUser", JSON.stringify(authenticationData));

        if (window.location.pathname === "/login") {
          window.location.href = "/";
        } else {
          window.location.reload();
          window.history.replaceState({}, "", "/login");
        }
      }
    } catch (error) {
      // Handle sign-in errors
    }
  };

  return (
    <div>
      <div className="loginMobileBox">
        <div className="loginMobileHeaderDiv">
          <img
            src={app_icon2}
            className="loginMobileIcon"
            width="25"
            height="25"
          />
          <p class="loginHeaderTitle">Login</p>
        </div>
        <div className="loginMobileDivInputs">
          <input
            id="username"
            className="loginMobileUsernameInput"
            placeholder="Enter username or email"
          ></input>

          <input
            id="password"
            className="loginMobilePasswordInput"
            placeholder="Enter password"
          ></input>
        </div>
        <button
          id="loginButton"
          class="loginMobileButton"
          disabled={isButtonDisabled}
          onClick={handleSubmit}
        >
          {button}
        </button>
        <div className="loginMobileOptionsDiv">
          <p id="loginForgot" class="loginMobileForgot" onClick={goForgot}>
            Forgot password?
          </p>

          <p id="loginForgot" class="loginMobileCreate" onClick={goRegister}>
            Register
          </p>
        </div>
        <hr className="lineView" />
        <button
          id="gSignInWrapper"
          className="loginMobileGoogle"
          value="submit"
          onClick={signInWithGoogle}
        >
          <img src={google_icon2} className="googleImage" alt="Google Icon" />
          Continue with Google
        </button>
      </div>
      <div className="loginFooterDiv">
        <div className="footerView">
          <img className="footerImg" src={app_icon} alt="Image" />
          <FooterComponent />
        </div>

        <p className="copyrightLabel">{copyrightLabel}</p>
      </div>
    </div>
  );
};

export default LoginMobile;
