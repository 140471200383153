import { userAuthenticated } from "../extension/currentUser";
import { purchase_validity, url_path } from "../extension/exportedStrings";

export const purchaseValidity = async (unique_id) => {
  const url = url_path + purchase_validity;
  const data = {
    username: userAuthenticated,
    unique_id: unique_id,
  };

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const result = await response.json();
    const status = result.status;
    
    return status;
  } catch (error) {
    return false;
  }
};
