/** @format */
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import CryptoJS from "crypto-js";

import "./../style/login.css";
import "./../style/footer.css";

import {
  app_icon,
  app_icon2,
  apple_icon,
  google_icon,
  google_icon2
} from "./../extension/exportedIcons";

import {
    check,
  copyrightLabel,
  googleLoginSecretKey,
  register,
  url_path,
} from "./../extension/exportedStrings";

import FooterComponent from "./../design/Footer.js";
import { userAuthenticated } from "../extension/currentUser.js";
import { validateUsername } from "../extension/inputValidity.js";
import { createAcc } from "../extension/registerAccount.js";

import "./../style/create.css";

const CreateThirdPartyMobile = () => {
  const navigate = useNavigate();
  const location = useLocation();

   const [button, setButton] = useState("Create account");
   const [valid, setValid] = useState(false);
  
   const [usernameValue, setUsername] = useState("");
   const [fullnameValue, setFullnameValue] = useState("");
   const [emailValue, setEmailValue] = useState("");

 useEffect(() => {
     document.title = "SociRank - Register"; 
 
     const currentPath = location.pathname;
 
     const part = currentPath.split("/");
     const base64EncodedData = part[2];
     const secretKey = googleLoginSecretKey; // Replace with your secret key
     // Decode the Base64 data
     const encryptedData = atob(base64EncodedData);
 
     // Decrypt the data with AES decryption
     const decryptedBytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
 
     // Convert the decrypted bytes to a string
     const decryptedData = decryptedBytes.toString(CryptoJS.enc.Utf8);
 
     const infoSplit = decryptedData.split(" ");
 
     setEmailValue(infoSplit[0]);
     setFullnameValue(infoSplit[1]);
   }, []);
 
   const detailCheck = async (value, type) => {
     if(type == "username"){
         if(!validateUsername(value)){
            const item = document.getElementById(type);
            item.style.borderColor = "red";
            setButton("Create Account")
            return;
         }
     }
 
     setButton("Validating username...")
 
     const url =  url_path + check; 
     const data = {
       username: value, // Replace with the actual username
       type: type, 
     };
 
     fetch(url, {
       method: "POST",
       headers: {
         "Content-Type": "application/json",
       },
       body: JSON.stringify(data),
     })
       .then((res) => {
         if (!res.ok) {
           throw new Error(`HTTP error! Status: ${res.status}`);
         }
         return res.json();
       })
       .then((data) => {
         // Handle the response data here
         if(data == "success"){
           const item = document.getElementById(type);
           // Change the border color to red
           item.style.borderColor = "#AFE1AF";
           setUsername(value.trim());
           setValid(true)
         }else{
           const item = document.getElementById(type);
           // Change the border color to red
           item.style.borderColor = "red";
 
           setValid(false)
         }
         setButton("Create Account")
       })
       .catch((error) => {
         // Handle any errors that occurred during the fetch
         console.error("Fetch error:", error);
         setButton("Create Account")
         setValid(false)
       });
   };
 
   const createAccount = async () => {
     if (!valid) return;
        setButton("Creating account...")
 
        const url = url_path + register; 
        
         const success = await createAcc(url, usernameValue, fullnameValue, emailValue, "", "");
        
         if (success) {
           const authenticationData = {
             username: String(usernameValue).toLowerCase(),
           };
     
           // Save the data to localStorage
           localStorage.setItem('currentUser', JSON.stringify(authenticationData));
     
           let path = `/`;
           navigate(path);
           window.location.reload();
         } else {
           setButton("Account creation failed.");
         }
   }


  return (
    <div>
      <div className="loginBody">
        <div className="forgotPasswordMobileBox">
                <div className="createHeaderDiv">
                  <img
                    src={app_icon2}
                    className="registerMobileIcon"
                    width="25"
                    height="25"
                  />
                </div>
                <p class="loginHeaderTitle">Choose a username</p>
                <input
                  className="loginMobileForgotEmailInput"
                  id="username"
                  name="username"
                  placeholder="Enter username"
                  onChange={(e) => detailCheck(e.target.value, "username")}
                ></input>
                <p id="tosLabel" class="regtosLabel">
                  By clicking register, you agree that you have read and accepted the
                  <a href="/terms-condition" className="link" target="_blank">
                    {" "}
                    Terms of Use
                  </a>
                </p>
                <button class="createButton" onClick={createAccount}>
                    {button}
                </button>
                <p class="registerMobileUsernameError"></p>
              </div>
      </div>
      <div className="loginFooterDiv">
        <div className="footerView">
          <img
            className="footerImg"
            src={app_icon}
            alt="Image"
          />
          <FooterComponent />
        </div>

          <p className="copyrightLabel">
            {copyrightLabel}
          </p>
      </div>
    </div>
  );
};

export default CreateThirdPartyMobile;
