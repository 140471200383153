/** @format */

import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";

import { userAuthenticated } from "./../extension/currentUser";
import { customDate } from "./../extension/currentDate";
import { clipboardLink } from "./../extension/copyClipboard";

import { convertPriceToStripe } from "./../extension/stripeConverter";
import { app_icon2, profile_icon } from "./../extension/exportedIcons";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import CheckoutForm from "./CheckoutForm";
import MinibarComponent from "./Minibar.js";
import FooterComponent from "./Footer.js";

import "./../style/coin.css";

import { cashName, cashPriceBuy, cashAmountBuy } from "./../data/cashArray";

import {
  url_path,
  inviteLink,
  websiteLink,
  paymentIntentPath,
} from "./../extension/exportedStrings";


import { app_icon, soci_coin } from "./../extension/exportedIcons";
import { copyrightLabel, stripePublishableKey } from "./../extension/exportedStrings";
import LoadingDialog from "../dialog/LoadingDialog.js";

const Coin = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [limit, setLimit] = useState(0);
  const [button, setButton] = useState("Copy link");
  
  const stripePromise = loadStripe(stripePublishableKey);
  const [clientSecret, setClientSecret] = useState("");
  const [priceSelected, setPriceSelected] = useState("");

  var personalInviteLink = "";
  
  useEffect(() => {
    document.title = "SociRank - Get Coins"; // Change the title here
    
    const getLink = () =>{
      const url = url_path + inviteLink;
  
      const data = {
        username: userAuthenticated,
      };
  
      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error(`HTTP error! Status: ${res.status}`);
          }
          return res.json();
        })
        .then((data) => {
          const dataArray = JSON.parse(data);
          personalInviteLink = websiteLink + dataArray.unique_link;
        })
        .catch((error) => {
          // Handle any errors that occurred during the fetch
          console.error("Fetch error:", error);
        });
    }
    
    getLink();

  }, []);

  const copyLink = () => {
    if(personalInviteLink != ""){
       setButton("Link copied")
       clipboardLink(personalInviteLink)
    }
  };

  const createPayment = (price) => {
    openLoadingDialog();

    const url = url_path + paymentIntentPath;

    setPriceSelected(price)
    const updatedPrice = convertPriceToStripe(price);
    
    const data = {
      price: updatedPrice, // Replace with the actual price
    };

    fetch(url, {
      method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
    }).then(async (result) => {
      var { clientToken } = await result.json();

      setClientSecret(clientToken);
      
      closeLoadingDialog();
      openDialog();
    }).catch((error) => {
      closeLoadingDialog();
    });
    
  }

  const openDialog = () => {
    setIsOpen(true);
    document.body.style.overflow = "hidden";
  };

  const openLoadingDialog = () => {
    setIsLoading(true);
    document.body.style.overflow = "hidden";
  };

  const closeLoadingDialog = () => {
    setIsLoading(false);
    document.body.style.overflowY = "auto";
  };

  return (
    <div>
       <MinibarComponent />

       <div className="leftCoinDiv">
          <div className="instructionDiv">
             <p className="instructionTitle">Earn SociCoin</p>
             <p className="instructionLabel">One of the most common ways to earn SociCoins is by posting videos and receiving likes on the SociRank mobile app </p>
             <p className="instructionLabel2">For every thousand likes you gain 1 SociCoin </p>
             <div className="instructionView"></div>
             <p className="instructionLabel2">Earn 5 SociCoins for every friend or family member who signs up using your link.</p>
             <button className="cashButton" onClick={copyLink}>{button}</button>
          </div>
       </div>

       <div className="buyCoinDiv">
          <p className="instructionTitle">Buy SociCoin</p>
          <p className="instructionLabel">Users can use SociCoins to purchase items within SociRank or to acquire items listed in other users' stores.</p>
          <div className="instructionView"></div>
          <div className="coin-container">
            {cashName.map((array, index) => (
                <div className="coinTableDiv">
                <img src={soci_coin} className="coinImg" width="50" height="50" />
                <div class="coinInfoDiv">
                  <p className="coinDivLabel">{cashPriceBuy[index]} SociCoin</p>
                  {/* <p className="buyDivLabel">Additional: gain boost in post for 7 days</p> */}
                </div>
                <button className="coinDivButton" onClick={() => createPayment(cashAmountBuy[index])}>Buy for {cashAmountBuy[index]}</button>
                </div>
            ))}
          </div>
       </div>

       <div className="footerDiv2">
        <div className="footerView">
          <img
            className="footerImg"
            src={app_icon}
            alt="Image"
          />
          <FooterComponent />
        </div>

          <p className="copyrightLabel">
            {copyrightLabel}
          </p>
        </div>
        {clientSecret !== "" && isOpen && (
          <Elements stripe={stripePromise} options={{ clientSecret }}>
            <CheckoutForm price={priceSelected} isOpen={isOpen} setIsOpen={setIsOpen}/>
          </Elements>
        )}

        <LoadingDialog isOpen={isLoading} setIsOpen={setIsLoading}/>
    </div>
  );
};

export default Coin;
