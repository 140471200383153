/** @format */

import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { app_icon, app_icon2 } from "./../extension/exportedIcons";
import { url_path, check, copyrightLabel, forgotPassword } from "./../extension/exportedStrings";
import { validateEmail } from "./../extension/inputValidity";

import "./../style/forgotpassword.css";
import FooterComponent from "./Footer.js";

import CryptoJS from 'crypto-js';
import { googleLoginSecretKey } from "./../extension/exportedStrings";
function ForgotPassword() {
  const navigate = useNavigate();

  const [buttonText, setButtonText] = useState("Send reset link to email");
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  var emailOk = false
  var emailValue = ""

  useEffect(() => {
    document.title = "SociRank - Forgot Password";
  }, []);

  const detailCheck = () =>{
    if(buttonText == "Processing...") return;

    setButtonText("Processing...")
    const email = document.getElementById("email");

    if(!validateEmail(email.value.trim())){
        email.style.borderColor = "red";
        setButtonText("Send reset link to email")

        return;
    }
    const url =  url_path + forgotPassword; 
    const data = {
        email: email.value.trim(), // Replace with the actual username
      };

    fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error(`HTTP error! Status: ${res.status}`);
          }
          return res.json();
        })
        .then((data) => {
          // Handle the response data here
          if(data == "success"){
            // Change the border color to red
            email.style.borderColor = "#AFE1AF";;
            setButtonText("Reset link has been sent")
          }else{
            // Change the border color to red
            email.style.borderColor = "red";
            setButtonText("Error sending reset link to email")

          }
        })
        .catch((error) => {
          // Handle any errors that occurred during the fetch
          console.error("Fetch error:", error);
          setButtonText("Error sending reset link to email")
        });
  }
  
  return (
    <div>
      <div className="createBackground">
        <div className="divBox">
          <img src={app_icon2} className="divImage" width="25" height="25" />
          <p className="createLabel">Enter email of the account you wish to recover</p>
          <input
            className="createInput"
            id="email"
            name="email"
            placeholder="Enter email"
            onBlur={(e) => detailCheck()}
            autoFocus
          ></input>

          <p className="bottomLabel" id="bottomLabel">
            Email has been sent
          </p>
          <button class="createButton" onClick={detailCheck}>
            {buttonText}
          </button>
        </div>
        <div className="loginFooterDiv">
        <div className="footerView">
          <img
            className="footerImg"
            src={app_icon}
            alt="Image"
          />
          <FooterComponent />
        </div>

          <p className="copyrightLabel">
            {copyrightLabel}
          </p>
      </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
