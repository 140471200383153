/** @format */

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import UpdateSale from "./../design/UpdateSale";

import MinibarComponent from "./../design/Minibar.js";
import FooterComponent from "./../design/Footer.js";

import { userAuthenticated } from "./../extension/currentUser";

import { app_icon } from "./../extension/exportedIcons";
import {
  url_path,
  retrieveOrder,
  retrieveSales,
  copyrightLabel,
  deleteOrder,
} from "./../extension/exportedStrings";

import "./../style/order-manage.css";
import CartImage from "../extension/setCartIcon.js";
import ErrorDialog from "../dialog/ErrorDialog.js";
import { formatDate } from "../extension/timestampToDate.js";
import TrackingSafetyDialog from "../dialog/TrackingSafetyDialog.js";
import ConfirmSaleDialog from "../dialog/ConfirmSaleDialog.js";
import { getStatusFromText } from "../extension/orderStatusConfiguration.js";
import OrderProofDialog from "../dialog/OrderProofDialog.js";

function OrderManage() {
  const navigate = useNavigate();
  const [selectedChoice, setSelectedChoice] = useState("buyer");
  const [isUpdate, setUpdate] = useState(false);
  const [isErrorOpen, setIsErrorOpen] = useState(false);
  const [isSafetyOpen, setIsSafetyOpen] = useState(false);
  const [isConfirmSaleOpen, setIsConfirmSaleOpen] = useState(false);
  const [isOrderProofOpen, setIsOrderProofOpen] = useState(false);

  const [selectedOrder, setSelectedOrder] = useState([]);
  const [orderArray, setOrderArray] = useState([]);
  const [salesArray, setSalesArray] = useState([]);

  const [orderID, setOrderID] = useState("");
  const [trackingLabel, setTrackingLabel] = useState("");

  const [orderLabel, setOrderLabel] = useState("");

  const [isLoading, setIsLoading] = useState([true]);

  const orderStatusInitialLabel =
    "Your order is being processed. Please contact the seller for updates if there is a delay.";
  const saleStatusInitialLabel =
    "Please update your sale information at your earliest convenience.";

  useEffect(() => {
    const configOrder = () => {
      const url = url_path + retrieveOrder;
      const data = {
        username: userAuthenticated, // Replace with the actual username
      };

      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error(`HTTP error! Status: ${res.status}`);
          }
          return res.json();
        })
        .then((data) => {
          const filteredData = data.filter((item) => item.delivery !== "-");
          setOrderArray(filteredData);
        })
        .catch((error) => {});
    };

    const configSales = () => {
      const url = url_path + retrieveSales;
      const data = {
        username: userAuthenticated, // Replace with the actual username
      };

      fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error(`HTTP error! Status: ${res.status}`);
          }
          return res.json();
        })
        .then((data) => {
          const filteredData = data.filter((item) => item.delivery !== "-");
          setSalesArray(filteredData);
          setIsLoading(false);
          console.log(data)
        })
        .catch((error) => {
          setIsLoading(false);
          console.log(error);
        });
    };

    configOrder();
    configSales();
  }, []);

  const handleChoiceClick = (choice) => {
    setSelectedChoice(choice);
  };

  const openUpdate = (orders) => {
    if (!isUpdate) {
      setOrderID(orders.id);
      setOrderLabel(orders.delivery);
      setTrackingLabel(orders.tracking);
      setSelectedOrder(orders);

      setUpdate(true);
      document.body.style.overflowY = "hidden";
    }
  };

  const goToItem = (order) => {
    const path = `/item/${order.order_id}`;
    navigate(path);
  };

  const goMessage = (value) => {
    let path = `/message/${value}`;
    navigate(path);
  };

  const openError = () => {
    setIsErrorOpen(true);
    document.body.style.overflowY = "hidden";
  };

  const openSafety = (tracking) => {
    setTrackingLabel(tracking);
    setIsSafetyOpen(true);
    document.body.style.overflowY = "hidden";
  };

  const openConfirm = (orders) => {
    setOrderID(orders.id);

    setIsConfirmSaleOpen(true);
    document.body.style.overflowY = "hidden";
  };

  const openOrderProof = (orders) => {
    setSelectedOrder(orders);
    setOrderID(orders.id);

    setIsOrderProofOpen(true);
    document.body.style.overflowY = "hidden";
  };

  const orderDelete = (id) => {
    const url = url_path + deleteOrder;
    const data = {
      id: id, // Replace with the actual username
    };

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
       
      })
      .catch((error) => {

      });
  };

  return (
    <div>
      <MinibarComponent />
      <div className="order-manageDiv">
        <div className="orderTableDiv">
          {isLoading ? (
            <div className="spinner-container">
              <div className="spinner"></div>
            </div>
          ) : selectedChoice === "buyer" ? (
            <table className="orderTable">
              <tbody>
                {orderArray.length > 0 ? (
                  orderArray.map((orders, index) => (
                    <tr className="orderRow" key={index}>
                      <div className="orderHeader">
                        <div className="orderHeaderDiv">
                          <div className="orderHeaderDivLeft">
                            <div className="orderHeaderLabel">
                              <p className="orderLabel">Order no.</p>
                              <p className="orderLabel2">ORDER # {orders.id}</p>
                            </div>
                          </div>

                          <div className="orderHeaderDivRight">
                            <div className="orderHeaderLabel">
                              <p className="orderLabel">Order placed</p>
                              <p className="orderLabel2">
                                {formatDate(orders.date)}
                              </p>
                            </div>

                            <div className="orderHeaderLabel">
                              <p className="orderLabel">Price</p>
                              <p className="orderLabel2">{orders.price}</p>
                            </div>

                            <div className="orderHeaderLabel">
                              <p className="orderLabel">Deliver to</p>
                              <p className="orderLabelHighlight">
                                {orders.address}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="orderBody">
                        <div className="orderBodyDivLeft">
                          <CartImage
                            timestring={orders.timestring}
                            currentPath={orders.username}
                            type={"item"}
                            onClick={() => goToItem(orders)}
                          />
                          <div className="orderTextDiv">
                            <p
                              className="orderUsername"
                              onClick={() => goToItem(orders)}
                            >
                            {`${orders.itemName}${
                                [orders.material, orders.color, orders.model, orders.other]
                                  .filter((value) => value).length > 0 // Check if there's any non-empty value
                                  ? ` - ${[orders.material, orders.color, orders.model, orders.other]
                                      .filter((value) => value)
                                      .join(", ")}` // Include hyphen and join non-empty values
                                  : ""
                              }`}                         
                            </p>
                            <p
                              className="orderArrival"
                              onClick={() => goMessage(orders.username)}
                            >
                              {orders.delivery === "ordered"
                                ? orderStatusInitialLabel
                                : orders.delivery}
                            </p>
                          </div>
                        </div>

                        <div className="orderBodyDivRight">
                          <button
                            className="orderUpdate"
                            onClick={() => {
                              if (
                                orders.tracking === "-" ||
                                orders.tracking === ""
                              ) {
                                openError();
                              } else {
                                openSafety(orders.tracking);
                              }
                            }}
                          >
                            Track package
                          </button>
                          <button
                            className="orderUpdate"
                            onClick={() => {
                              if (orders.delivery === "ordered") {
                                orderDelete(orders.id);
                                const modifiedOrders = orderArray.filter((order) => order.id !== orders.id); // Filter out the current order
                                setOrderArray(modifiedOrders); // Update the state with the modified orders
                              }else if(getStatusFromText(orders.delivery) == 5){
                                openOrderProof(orders);
                              }else {
                                const mailtoLink = `mailto:socirank@gmail.com?subject=Refund Request&body=Hello, I would like to inquire about the following order: No.${orders.id}`;
                                window.open(mailtoLink, "_blank");
                              }
                            }}
                          >
                            {getStatusFromText(orders.delivery) === 5
                              ? "Order Proof"
                              : orders.delivery === "ordered"
                              ? "Cancel Order"
                              : "Refund Order"}
                          </button>
                        </div>
                      </div>
                    </tr>
                  ))
                ) : (
                  <div className="choiceErrorDiv">
                    <p className="choiceErrorLabel">No current purchases</p>
                  </div>
                )}
              </tbody>
            </table>
          ) : (
            <table className="orderTable">
              <tbody>
                {salesArray.length > 0 ? (
                  salesArray.map((orders, index) => (
                    <tr className="orderRow" key={index}>
                      <div className="orderHeader">
                        <div className="orderHeaderDiv">
                          <div className="orderHeaderDivLeft">
                            <div className="orderHeaderLabel">
                              <p className="orderLabel">Sales no.</p>
                              <p className="orderLabel2">ORDER # {orders.id}</p>
                            </div>
                          </div>

                          <div className="orderHeaderDivRight">
                            <div className="orderHeaderLabel">
                              <p className="orderLabel">Sale placed</p>
                              <p className="orderLabel2">
                                {formatDate(orders.date)}
                              </p>
                            </div>

                            <div className="orderHeaderLabel">
                              <p className="orderLabel">Price</p>
                              <p className="orderLabel2">{orders.price}</p>
                            </div>

                            <div className="orderHeaderLabel">
                              <p className="orderLabel">Deliver to</p>
                              <p className="orderLabelHighlight">
                                {orders.address}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="orderBody">
                        <div className="orderBodyDivLeft">
                          <CartImage
                            timestring={orders.timestring}
                            currentPath={orders.username}
                            type={"item"}
                            onClick={() => goToItem(orders)}
                          />
                          <div className="orderTextDiv">
                            <p
                              className="orderUsername"
                              onClick={() => goToItem(orders)}
                            >
                              {`${orders.itemName}${
                                [orders.material, orders.color, orders.model, orders.other]
                                  .filter((value) => value).length > 0 // Check if there's any non-empty value
                                  ? ` - ${[orders.material, orders.color, orders.model, orders.other]
                                      .filter((value) => value)
                                      .join(", ")}` // Include hyphen and join non-empty values
                                  : ""
                              }`}          
                            </p>
                            <p
                              className="orderArrival"
                              onClick={() => goMessage(orders.purchased_by)}
                            >
                              {orders.delivery === "ordered"
                                ? saleStatusInitialLabel
                                : orders.delivery}
                            </p>
                          </div>
                        </div>

                        <div className="orderBodyDivRight">
                          <button
                            className="orderUpdate"
                            onClick={() =>
                              openUpdate(orders)
                            }
                          >
                            Update Sale
                          </button>
                          <button
                            className="orderUpdate"
                            onClick={() => {
                              if(getStatusFromText(orders.delivery) == 5) return;

                              openConfirm(orders);
                            }}
                          >
                            {getStatusFromText(orders.delivery) === 5 ? "Confirmed" : "Confirm Sale"}
                          </button>
                        </div>
                      </div>
                    </tr>
                  ))
                ) : (
                  <div className="choiceErrorDiv">
                    <p className="choiceErrorLabel">No current sales</p>
                  </div>
                )}
              </tbody>
            </table>
          )}
        </div>
        <div className="orderChoiceDiv">
          <div
            className={`buyerChoiceDiv ${
              selectedChoice === "buyer" ? "choiceSelected" : ""
            }`}
            onClick={() => handleChoiceClick("buyer")}
          >
            <p className="choiceLabel">Orders</p>
          </div>

          <div
            className={`sellerChoiceDiv ${
              selectedChoice === "seller" ? "choiceSelected" : ""
            }`}
            onClick={() => handleChoiceClick("seller")}>
            <p className="choiceLabel">Sales</p>
          </div>
        </div>
      </div>
      <div className="loginFooterDiv">
        <div className="footerView">
          <img className="footerImg" src={app_icon} alt="Image" />
          <FooterComponent />
        </div>

        <p className="copyrightLabel">{copyrightLabel}</p>
      </div>

      <UpdateSale
        isOpen={isUpdate}
        setIsOpen={setUpdate}
        orderID={orderID}
        trackingLabel={trackingLabel}
        orderSelected={selectedOrder}
        orderArray={orderArray}
        setOrderArray={setOrderArray}
      />

      <TrackingSafetyDialog
        isOpen={isSafetyOpen}
        setIsOpen={setIsSafetyOpen}
        trackingValue={trackingLabel}
      />

      <ConfirmSaleDialog
        isOpen={isConfirmSaleOpen}
        setIsOpen={setIsConfirmSaleOpen}
        orderID={orderID}
      />

      <OrderProofDialog
        isOpen={isOrderProofOpen}
        setIsOpen={setIsOrderProofOpen}
        orderID={orderID}
        order={selectedOrder}
      />

      <ErrorDialog
        isOpen={isErrorOpen}
        setIsOpen={setIsErrorOpen}
        text="Tracking information is currently unavailable. Please contact the seller for assistance."
      />
    </div>
  );
}

export default OrderManage;
