export const createAcc = async (url, username, fullname, email, password, dob) => {
  console.log(email);
  const data = {
    username: username,
    fullname: fullname,
    email: email,
    password: password,
    dob: dob,
  };

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      // Handle non-2xx HTTP responses
      console.error(`HTTP error! Status: ${response.status}`);
      return false; // Indicate failure
    }

    const responseData = await response.json();

    // Check if the response data contains "success"
    if (responseData && responseData.body && responseData.body.includes("success")) {
        return true;
    } else {
      console.log(responseData);
        return false;
    }
  } catch (error) {
    // Handle any errors that occurred during the fetch
    return false; // Indicate failure
  }
};
