/** @format */
import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import "./../style/login.css";
import "./../style/footer.css";

import {
  app_icon,
  app_icon2,
  apple_icon,
  google_icon,
  google_icon2,
} from "./../extension/exportedIcons";

import { copyrightLabel, forgotPassword, url_path } from "./../extension/exportedStrings";

import FooterComponent from "./../design/Footer.js";
import { userAuthenticated } from "../extension/currentUser.js";
import { validateEmail } from "../extension/inputValidity.js";

const ForgotPasswordMobile = () => {
  const navigate = useNavigate();

  const [buttonText, setButtonText] = useState("Send reset link to email");
  
  useEffect(() => {
    document.title = "SociRank - Forgot Password"; // Change the title here
  }, []);

  const detailCheck = () =>{
      if(buttonText == "Processing...") return;
  
      setButtonText("Processing...")
      const email = document.getElementById("email");
  
      if(!validateEmail(email.value.trim())){
          email.style.borderColor = "red";
          setButtonText("Send reset link to email")
  
          return;
      }
      const url =  url_path + forgotPassword; 
      const data = {
          email: email.value.trim(), // Replace with the actual username
        };
  
      fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        })
          .then((res) => {
            if (!res.ok) {
              throw new Error(`HTTP error! Status: ${res.status}`);
            }
            return res.json();
          })
          .then((data) => {
            // Handle the response data here
            if(data == "success"){
              // Change the border color to red
              email.style.borderColor = "#AFE1AF";;
              setButtonText("Reset link has been sent")
            }else{
              // Change the border color to red
              email.style.borderColor = "red";
              setButtonText("Error sending reset link to email")
  
            }
          })
          .catch((error) => {
            // Handle any errors that occurred during the fetch
            console.error("Fetch error:", error);
            setButtonText("Error sending reset link to email")
          });
    }

  return (
    <div>
      <div className="forgotPasswordMobileBox">
        <div className="loginMobileHeaderDiv">
          <img
            src={app_icon2}
            className="loginMobileIcon"
            width="25"
            height="25"
          />
          <p class="loginHeaderTitle">Forgot Password</p>
        </div>
        <p className="forgotPasswordMobileLabel">Enter email of the account you wish to recover</p>
        <input
            id="email"
            className="loginMobileForgotEmailInput"
            placeholder="Enter email"
            onBlur={(e) => detailCheck()}
        ></input>
           <button class="loginMobileForgotPasswordButton" onClick={detailCheck}>
            {buttonText}
          </button>
      </div>
      <div className="loginFooterDiv">
        <div className="footerView">
          <img className="footerImg" src={app_icon} alt="Image" />
          <FooterComponent />
        </div>

        <p className="copyrightLabel">{copyrightLabel}</p>
      </div>
    </div>
  );
};

export default ForgotPasswordMobile;
