import { database, ref, set, get } from './firebase';

export const addErrorFunction = (type, title, url, username, error) => {
    const databaseRef = ref(database, `error/${type}/${new Date().getTime()}`);
  
    // Ensure no undefined values in the data object
    const data = {
      title: title || "Unknown Title", 
      url: url,      // Fallback to "Unknown Title" if undefined
      username: username || "Unknown User", // Fallback to "Unknown User" if undefined
      error: error || "No error details",   // Fallback to "No error details" if undefined
      timestring: new Date().toISOString() // Fallback to current timestamp if undefined
    };
  
    return set(databaseRef, data);
};

export const getErrorCount = async (type) => {
  try {
    const databaseRef = ref(database, `error/${type}`);
    const snapshot = await get(databaseRef);

    if (snapshot.exists()) {
      const data = snapshot.val();
      console.log(Object.keys(data).length);
    } 
  } catch (error) {
    console.error("Error retrieving data:", error);
  }
};

