import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import MinibarComponent from "./Minibar.js";

import "./../style/completion.css";
import { purchased, soci_coin } from '../extension/exportedIcons.js';

function Completion() {
  const navigate = useNavigate();

  const goHome = () => {
    let path = `/`;
    navigate(path);
  }

  return (
    <div>
        <MinibarComponent />
        <div className='CompletionDiv'   style={{ backgroundImage: `url(${purchased})` }}>
           <div className='completionDesignDiv'>
              <img src={soci_coin} className="completionImg"/>
              <p className='completionLabel'>Hooray! Your purchase was a success. Thank you for choosing us!</p>
              <button className="completionDivButton" onClick={goHome}>Click here to return home</button>
           </div>
        </div>
    </div>
  );
}

export default Completion;