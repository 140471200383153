/** @format */
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import CheckoutFormOrder from "./CheckoutFormOrder";
import MinibarComponent from "./Minibar.js";
import FooterComponent from "./Footer.js";

import { updateBalance, userAuthenticated } from "../extension/currentUser.js";

import { app_icon } from "./../extension/exportedIcons";
import { copyrightLabel, url_path, createOrder, paymentIntentPath, stripePublishableKey, purchaseWithCoins } from "./../extension/exportedStrings";
import { top_icon } from "./../extension/merchImg";

import { CartImage } from "./../extension/setCartIcon.js";
import { updateSelected, deleteCart } from './items/functions/cartFunctions.js';
import "./../style/checkout.css";
import { fetchAddress } from "../data/retrieveAddress.js";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { convertPriceToStripe } from "./../extension/stripeConverter";
import { generateUUID } from "../extension/generateUUID.js";
import CheckoutChoiceDialog from "../dialog/CheckoutChoiceDialog.js";
import { addListToNotificationAndClearCart, updateOrderStatus } from "../data/reusuableBuyData.js";
import { calculateSociCoin } from "../extension/socicoin_calculation.js";
import { formatMoney } from "../extension/moneyConvert.js";

const Checkout = () => {
  const navigate = useNavigate();
  const [deliveryAddress, setDeliveryAddress] = useState("");
  const [cartArray, setCartArray] = useState(JSON.parse(localStorage.getItem('cartArray')) || []);
  const [selectedCart, setSelectedCart] = useState([]);
  const [selectedItemsCount, setSelectedItemsCount] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [inProgress, setInProgress] = useState(false);

  const [isBuyOpen, setBuyOpen] = useState(false);

  const stripePromise = loadStripe(stripePublishableKey);
  const [clientSecret, setClientSecret] = useState("");

  const [isOpen, setIsOpen] = useState(false);

  const unique_id = generateUUID();

  const [convertedPrice, setConvertedprice] = useState(0);
  const [convertedSociCoin, setConvertedSociCoin] = useState(0);

  useEffect(() => {
    document.title = "SociRank - Checkout";
    
    const updateAddress = async () => {
      const address = await fetchAddress();
      if (address === "Enter your address") {
        setDeliveryAddress("No Address Set"); // Set a default message if no address is found
      } else {
        setDeliveryAddress(address); // Set the fetched address
      } 
    };
    
    updateAddress();
    convertCoins();

  }, []);

  useEffect(() => {
    const fetchData = () => {
      const count = cartArray.filter(item => item.selected === true).length;
      setSelectedItemsCount(count); // Set the state outside the object

      const totalPrice = cartArray
      .filter(item => item.selected === true) // Filter selected items
      .reduce((acc, item) => acc + item.itemPrice * item.itemQuantity, 0); // Sum up itemPrice * itemQuantity
  
      setTotalPrice(totalPrice);  // Set the total price state
    };
    
    fetchData();
  }, [cartArray]);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (inProgress) {
        event.preventDefault();
        event.returnValue = 'A purchase is in progress. Are you sure you want to leave?';
      }
    };
  
    const handleBackButton = (event) => {
      if (inProgress) {
        event.preventDefault();
        const confirmation = window.confirm('A purchase is in progress. Are you sure you want to go back?');
        if (!confirmation) {
          window.history.pushState(null, '', window.location.href); // Prevent back navigation
        }
      }
    };
  
    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('popstate', handleBackButton);
  
    // Prevent back navigation by pushing new state when the component mounts
    window.history.pushState(null, '', window.location.href);
  
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
      window.removeEventListener('popstate', handleBackButton);
    };
  }, [inProgress]);
  

  const convertCoins = () => {
    setConvertedprice(formatMoney(totalPrice));
    setConvertedSociCoin(calculateSociCoin(totalPrice));
  };

  const changeAddress = () => {
    const path = `/update-address`;
    navigate(path);
  };

  const goToItem = (item) => {
    if(item.type === "item"){
      const path = `/item/${item.itemUrl}`;
      navigate(path);
    }else{
      const path = `/digital/${item.itemUrl}`;
      navigate(path);
    }
  };
  
  const purchaseCart = (type) => {
    if (deliveryAddress === "No Address Set" || deliveryAddress.trim() === '') {
      changeAddress();
      return;
    }

    if(selectedItemsCount == 0) return;
    setInProgress(true);

    setSelectedCart(cartArray.filter(item => item.selected === true));

    const data = [];  // Initialize an empty array
  
    selectedCart.forEach(item => {
      var updatedQuantity = item.itemQuantity;
      if(updatedQuantity == 0){
        updatedQuantity = 1;
      }
      const totalPrice = item.itemPrice * updatedQuantity;
      data.push({
        unique_id: unique_id,
        username: item.username, 
        purchased_by: userAuthenticated, 
        order_id: item.itemUrl,
        date: Date.now(),  // Current timestamp
        price: totalPrice,  // Multiply price by quantity
        address: deliveryAddress, 
        quantity: item.itemQuantity, 
        tracking: "-", 
        delivery: "-",
        timestring: item.timestring,
        itemName: item.itemName,
        color: item.color,
        material: item.material,
        model: item.model,
        other: item.other,
      });
    });
  
    confirmOrder(data, type);
  };

  const confirmOrder = (orders, type) => {
    const url = url_path + createOrder;

    const data = {
      orders: orders,
    };

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        if(type == 1){
          createPayment();
        }else{
          purchaseWithSociCoin();
        }
      })
      .catch((error) => {
        setInProgress(false);
      });
  };

  const createPayment = () => {
    const url = url_path + paymentIntentPath;

    const totalPriceString = totalPrice.toString();
    const updatedPrice = convertPriceToStripe(totalPriceString);
    
    const data = {
      price: updatedPrice, // Replace with the actual price
    };

    fetch(url, {
      method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
    }).then(async (result) => {
      var { clientToken } = await result.json();
      setClientSecret(clientToken);
      openDialog();
      setInProgress(false);
    }).catch((error) => {
      setInProgress(false);
    });
  }

  const purchaseWithSociCoin = () => {
    setInProgress(true);
    const url = url_path + purchaseWithCoins;

    const data = {
      username: userAuthenticated,
      price: convertedSociCoin,
    };

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        updateOrderStatus(unique_id);
        addListToNotificationAndClearCart(selectedCart);
        setInProgress(false);

        const newBalance = parseFloat(localStorage.getItem('balance')) - convertedSociCoin;
        updateBalance(newBalance);

        goHome();
      })
      .catch((error) => {
        setInProgress(false);
      });
  };


  const openDialog = () => {
    setIsOpen(true);
    document.body.style.overflow = "hidden";
  };

  const openBuyDialog = () => {
    setBuyOpen(true);
    document.body.style.overflow = "hidden";
  };

  const goHome = () => {
    const path = `/`;
    window.location.href = path;
  }
  
  return (
    <div>
      <MinibarComponent />

      <div className="checkoutDiv">
        <table className="checkoutTable">
          <tbody>
            {cartArray.map((carts, index) => (
              <tr className="checkoutRow" key={index}>
                <div className="checkoutHeader">
                  <div className="checkoutHeaderDiv">
                    <div className="checkoutHeaderDivLeft">
                      <div className="checkoutHeaderLabel">
                        <p className="checkoutLabel">Availability</p>
                        <p className="checkoutLabel2">In stock</p>
                      </div>
                      <div className="checkoutHeaderLabel">
                        <p className="checkoutLabel">Price</p>
                        <p className="checkoutLabel2">${carts.itemPrice}</p>
                      </div>
                      <div className="checkoutHeaderLabel">
                        <p className="checkoutLabel">Quantity</p>
                        <p className="checkoutLabel2">{carts.itemQuantity || 1}</p>
                      </div>
                    </div>
                    <div className="checkoutHeaderDivRight">
                      {carts.color && (
                        <div className="checkoutHeaderLabel">
                          <p className="checkoutLabel">Color</p>
                          <p className="checkoutLabel2">{carts.color}</p>
                        </div>
                      )}
                      {carts.material && (
                        <div className="checkoutHeaderLabel">
                          <p className="checkoutLabel">Material</p>
                          <p className="checkoutLabel2">{carts.material}</p>
                        </div>
                      )}
                      {carts.model && (
                        <div className="checkoutHeaderLabel">
                          <p className="checkoutLabel">Model</p>
                          <p className="checkoutLabel2">{carts.model}</p>
                        </div>
                      )}
                      {carts.other && (
                        <div className="checkoutHeaderLabel">
                          <p className="checkoutLabel">Other</p>
                          <p className="checkoutLabel2">{carts.other}</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="checkoutBody">
                  <div className="checkoutBodyDivLeft" onClick={() => goToItem(carts)}>
                    <CartImage timestring={carts.timestring} currentPath={carts.username} type={carts.type} />
                    <div className="checkoutTextDiv">
                      <p className="checkoutUsername">{carts.itemName}</p>
                    </div>
                  </div>

                  <div className="checkoutBodyDivRight">
                    <button className="checkoutUpdate" onClick={() => deleteCart(carts, cartArray, setCartArray)}>Delete</button>
                    <button className="checkoutSelected" onClick={() => updateSelected(carts, cartArray, !carts.selected, setCartArray)}>
                        {carts.selected ? "Selected" : "Select"}
                    </button>
                  </div>
                </div>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="checkoutPurchaseDiv">
          {inProgress ? (
              <div className="item-container" id="itemContainer">
              <div className="spinner-container">
                <div className="spinner"></div>
              </div>
              </div>
          ) : (
            <>
              <div className="checkPurchaseLabels">
                <p className="checkPurchaseLabel">Delivery Address</p>
                <p className="checkPurchaseLabel2">{deliveryAddress}</p>
                <p className="checkPurchaseLabel3" onClick={changeAddress}>Change delivery address</p>
              </div>
              <div className="checkPurchaseLabels">
                <p className="checkPurchaseLabel2Alt">Total items: {selectedItemsCount}</p>
              </div>
              <div className="checkPurchaseLabels">
                <p className="checkPurchaseLabel2Alt">Total price: ${totalPrice}</p>
              </div>
              <button className="checkPurchaseButton" onClick={openBuyDialog}>Buy now</button>
            </>
          )}
      </div>

      <div className="footerDiv2">
        <div className="footerView">
          <img className="footerImg" src={app_icon} alt="Image" />
          <FooterComponent />
        </div>

        <p className="copyrightLabel">
          {copyrightLabel}
        </p>
      </div>

      <CheckoutChoiceDialog isOpen={isBuyOpen} setIsOpen={setBuyOpen} price={totalPrice} purchaseCart={purchaseCart} convertedPrice={convertedPrice} convertedSociCoin={convertedSociCoin} />

      {clientSecret !== "" && isOpen && (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <CheckoutFormOrder price={`$${totalPrice}`} isOpen={isOpen} setIsOpen={setIsOpen} unique_id={unique_id} selectedCart={selectedCart}/>
        </Elements>
      )}
    </div>
  );
};

export default Checkout;
