/** @format */
import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import "./../style/login.css";
import "./../style/footer.css";

import {
  app_icon,
  app_icon2,
  apple_icon,
  google_icon,
  google_icon2,
} from "./../extension/exportedIcons";

import {
  check,
  copyrightLabel,
  forgotPassword,
  register,
  url_path,
} from "./../extension/exportedStrings";

import FooterComponent from "./../design/Footer.js";
import { userAuthenticated } from "../extension/currentUser.js";
import { validateEmail, validateUsername } from "../extension/inputValidity.js";
import { createAcc } from "../extension/registerAccount.js";
import { addErrorFunction } from "../firebase/errorCheckFirebase.js";
import { addDetailFunction } from "../firebase/detailCheckFirebase.js";

const RegisterMobile = () => {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = "SociRank - Register"; // Change the title here

    addDetailFunction("website", "visited", "mobile web was visited");
  }, []);

  const [button, setButton] = useState("Register");
  const [errorMessage, setErrorMessage] = useState("");

  const [validArray, setValidArray] = useState([
    false,
    false,
    false,
    false,
    false,
  ]);

  const [usernameValue, setUsernameValue] = useState("");
  const [emailValue, setEmailValue] = useState("");
  const [passwordValue, setPasswordValue] = useState("");
  const [dobValue, setDOBValue] = useState("");
  const [fullnameValue, setFullname] = useState("");

  const detailCheck = async (value, type) => {
    const updatedValidArray = [...validArray]; // Create a copy of the array

    if (type == "username") {
      if (!validateUsername(value)) {
        const item = document.getElementById(type);
        item.style.borderColor = "red";
        setErrorMessage(
          "Username must be valid, without spaces, and under 24 characters."
        );
        return;
      }
    } else if (type == "email") {
      if (!validateEmail(value)) {
        const item = document.getElementById(type);
        item.style.borderColor = "red";
        setErrorMessage("Email is not valid");

        return;
      }
    }

    if(type == "username"){
        setErrorMessage("Checking username availability...");
    }else{
        setErrorMessage("Checking email availability...");
    }

    const url = url_path + check;
    const data = {
      username: value, // Replace with the actual username
      type: type,
    };

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(`HTTP error! Status: ${res.status}`);
        }
        return res.json();
      })
      .then((data) => {
        // Handle the response data here
        if (data == "success") {
          const item = document.getElementById(type);
          // Change the border color to red
          item.style.borderColor = "#AFE1AF";

          if (type == "username") {
            updatedValidArray[0] = true; // Update the value
            setValidArray(updatedValidArray); // Update the state
            setUsernameValue(value.trim());
          } else {
            updatedValidArray[1] = true; // Update the value
            setValidArray(updatedValidArray); // Update the state
            setEmailValue(value.trim());

          }
          setErrorMessage("username is available");
        } else {
          const item = document.getElementById(type);
          // Change the border color to red
          item.style.borderColor = "red";
          if (type == "username") {
            updatedValidArray[0] = false; // Update the value
            setValidArray(updatedValidArray); // Update the state
            addErrorFunction("register_detail", "mobile: username exists", url, usernameValue, data);
          } else {
            updatedValidArray[1] = false; // Update the value
            setValidArray(updatedValidArray); // Update the state
            addErrorFunction("register_detail", "mobile: email exists", url, emailValue, data);
          }
          setErrorMessage("email already exists");
        }
      })
      .catch((error) => {
        addErrorFunction("register_detail", "mobile: checking for username/email error", url, usernameValue, error);
        setErrorMessage("");
      });
  };

  const fullnameCheck = async (value) => {
    const updatedValidArray = [...validArray]; // Create a copy of the array

    if (value.trim() == "") {
      const item = document.getElementById("fullname");
      // Change the border color to red
      item.style.borderColor = "red";
      updatedValidArray[4] = false; // Update the value
      setValidArray(updatedValidArray); // Update the state
    } else {
      const item = document.getElementById("fullname");
      // Change the border color to red
      item.style.borderColor = "#AFE1AF";
      setFullname(value.trim());
      updatedValidArray[4] = true; // Update the value
      setValidArray(updatedValidArray); // Update the state
    }
  };

  const passwordCheck = async (value) => {
    const updatedValidArray = [...validArray]; // Create a copy of the array

    if (value.length < 8) {
      const item = document.getElementById("password");
      // Change the border color to red
      item.style.borderColor = "red";
      updatedValidArray[2] = false; // Update the value
      setValidArray(updatedValidArray); // Update the state
    } else {
      const item = document.getElementById("password");
      // Change the border color to red
      item.style.borderColor = "#AFE1AF";
      setPasswordValue(value.trim());
      updatedValidArray[2] = true; // Update the value
      setValidArray(updatedValidArray); // Update the state
    }
  };

  const dobCheck = async () => {
    const updatedValidArray = [...validArray]; // Create a copy of the array

    const dob1 = document.getElementById("dob1");
    const dob2 = document.getElementById("dob2");
    const dob3 = document.getElementById("dob3");

    if (
      dob1.value.trim() != "" &&
      dob2.value.trim() != "" &&
      dob3.value.trim() != ""
    ) {
      const item = (document.getElementById("dob1").style.borderColor =
        "#AFE1AF");
      const item2 = (document.getElementById("dob2").style.borderColor =
        "#AFE1AF");
      const item3 = (document.getElementById("dob3").style.borderColor =
        "#AFE1AF");
      // Change the border color to red
      updatedValidArray[3] = true; // Update the value
      setValidArray(updatedValidArray); // Update the state
      setDOBValue(dob1.value.trim() + "/" + dob2.value.trim() + "/" + dob3.value.trim());
    } else if (dob1.value.trim() == "") {
      const item = document.getElementById("dob1");
      // Change the border color to red
      item.style.borderColor = "red";
      updatedValidArray[3] = false; // Update the value
      setValidArray(updatedValidArray); // Update the state
    } else if (dob2.value.trim() == "") {
      const item = document.getElementById("dob2");
      // Change the border color to red
      item.style.borderColor = "red";
      updatedValidArray[3] = false; // Update the value
      setValidArray(updatedValidArray); // Update the state
    } else if (dob3.value.trim() == "") {
      const item = document.getElementById("dob3");
      // Change the border color to red
      item.style.borderColor = "red";
      updatedValidArray[3] = false; // Update the value
      setValidArray(updatedValidArray); // Update the state
    }
  };

  const createAccount = async () => {
    if (button === "Creating account...") return;

    if (validArray.every(Boolean)) {
      setButton("Creating account...");
      const url = url_path + register;

      const success = await createAcc(
        url,
        usernameValue,
        fullnameValue,
        emailValue,
        passwordValue,
        dobValue
      );

      if (success) {
        addDetailFunction("register_creation", "mobile: account created", `${usernameValue} created an account`);

        const authenticationData = {
          username: String(usernameValue).toLowerCase(),
        };

        // Save the data to localStorage
        localStorage.setItem("currentUser", JSON.stringify(authenticationData));

        let path = `/`;
        navigate(path);
        window.location.reload();
      } else {
        setButton("Try again");
        addErrorFunction("register_creation", "mobile: failed to create account", url, usernameValue, success);
      }
    }
  };

  return (
    <div>
      <div className="forgotPasswordMobileBox">
        <div className="loginMobileRegisterHeaderDiv">
          <img
            src={app_icon2}
            className="registerMobileIcon"
            width="25"
            height="25"
          />
          <p class="loginHeaderTitle">Register</p>
        </div>
        <input
          className="loginMobileForgotEmailInput"
          id="username"
          name="username"
          placeholder="Enter username"
          onBlur={(e) => detailCheck(e.target.value, "username")}
        ></input>
        <input
          className="loginMobileForgotEmailInput"
          id="fullname"
          name="fullname"
          placeholder="Enter fullname"
          onBlur={(e) => fullnameCheck(e.target.value)}
        ></input>
        <input
          id="email"
          className="loginMobileForgotEmailInput"
          placeholder="Enter email"
          onBlur={(e) => detailCheck(e.target.value, "email")}
        ></input>
        <input
          id="password"
          class="loginMobileForgotEmailInput"
          name="password"
          type="password"
          placeholder="Enter password"
          onBlur={(e) => passwordCheck(e.target.value)}
        />

        <div id="date-input" className="date-input" onBlur={(e) => dobCheck()}>
          <input id="dob1" type="number" placeholder="Day" min="1" max="31" />
          <input id="dob2" type="number" placeholder="Month" min="1" max="12" />
          <input
            id="dob3"
            type="number"
            placeholder="Year"
            min="1900"
            max="2099"
          />
        </div>

        <p id="tosLabel" class="regtosLabel">
          By clicking register, you agree that you have read and accepted the
          <a href="/terms-condition" className="link" target="_blank">
            {" "}
            Terms of Use
          </a>
        </p>

        <button class="loginMobileRegisterButton" onClick={createAccount}>
          {button}
        </button>
        <p class="registerMobileUsernameError">{errorMessage}</p>
      </div>
      <div className="loginFooterDiv">
        <div className="footerView">
          <img className="footerImg" src={app_icon} alt="Image" />
          <FooterComponent />
        </div>

        <p className="copyrightLabel">{copyrightLabel}</p>
      </div>
    </div>
  );
};

export default RegisterMobile;
