import { userAuthenticated } from "../extension/currentUser";
import { socicoin_purchase, url_path } from "../extension/exportedStrings";

export const purchaseSociCoin = async (price) => {
  const url = url_path + socicoin_purchase;
  const data = {
    username: userAuthenticated,
    price: price,
  };

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const result = await response.json();
    
    return result; // Return the result for further use if needed
  } catch (error) {
    return false;
  }
};
