/** @format */
import { PaymentElement } from "@stripe/react-stripe-js";
import { useState } from "react";
import { useStripe, useElements } from "@stripe/react-stripe-js";

import "./../style/blurVideo.css";
import { addListToNotificationAndClearCart, updateOrderStatus } from "../data/reusuableBuyData";
import { encryptData } from "../extension/dataEncryption";
import { purchase_key } from "../extension/exportedStrings";

const CheckoutFormOrder = ({ price,isOpen, setIsOpen, unique_id, selectedCart }) => {

  const [message, setMessage] = useState(`Pay ${price}`);
  
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async (e) => {
    const encryptedData = encryptData({ type: "checkout_order", selectedCart: selectedCart, unique_id: unique_id }, purchase_key);

    const return_url = `${
      window.location.origin
    }/processing/?data=${encodeURIComponent(encryptedData)}`;

    if (message === "Processing..." || message === "Redirecting...") return;
    setMessage("Processing...");

    e.preventDefault();

    if (!stripe || !elements) {
      console.error("Stripe.js has not yet loaded.");
      return;
    }

    try {
      const { error } = await stripe
        .confirmPayment({
          elements,
          confirmParams: {
            return_url: `${return_url}`, // Still triggers a redirect
          },
        })
        .then(function (result) {
          if (result.error) {
            console.error("Payment Error:", error);
            setMessage("Error with purchasing");
            return;
          }
        });
      setMessage("Redirecting...");
    } catch (err) {
      setMessage("Error with purchasing");
    }
  };

  const closeDialog = () => {
    setIsOpen(false);
    document.body.style.overflow = "auto";
  };

  return (
    <div>
      <div className={isOpen ? "darkenDiv" : "hidden"}>
        <form className="payment-form" onSubmit={handleSubmit}>
          <p className="paymentLabel">Complete purchase of item</p>
          <PaymentElement className="payment-element" />
          <div className="checkoutFormButtonDiv">
            <button
              type="button"
              className="cancel_buy-button"
              onClick={closeDialog}
            >
              Later
            </button>
            <button className="submit-button" type="submit">
              {message}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CheckoutFormOrder;
