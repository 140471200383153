export const configCart = (items, setCartString, cartArray) => {
    const itemInCart = cartArray.some(cartItem => cartItem.itemUrl === items.itemUrl);
    
    if (itemInCart) {
      setCartString("Added");
    }
};
  
  

export const addToCart = (items, cartString, setCartString, cartArray, quantity, color, material, model, other) => {
    if (cartString === "Added") {
      // Find the item in cartArray by its itemUrl and remove it
      const index = cartArray.findIndex((item) => item.itemUrl === items.itemUrl);
      if (index !== -1) {
        cartArray.splice(index, 1); // Remove the item at the found index
        // Update localStorage with the new cartArray
        localStorage.setItem('cartArray', JSON.stringify(cartArray));
      }
      setCartString("Add To Cart");
    } else {
      // Add the new item to cartArray
      cartArray.push({ 
        ...items, 
        itemQuantity: quantity,  // Add quantity
        color: color, 
        material: material,
        model: model,
        other: other,
        selected: true,        // Add selected field with default value
      });
      // Update localStorage with the new cartArray
      localStorage.setItem('cartArray', JSON.stringify(cartArray));
      setCartString("Added");
    }
};

export const updateSelected = (items, cartArray, selectedValue, setCartArray) => {
  // Find the index of the item in the cart
  const updatedCart = cartArray.map((item) => 
    item.itemUrl === items.itemUrl
      ? { ...item, selected: selectedValue }  // Create a new object with updated 'selected'
      : item
  );

  // Update the state and localStorage
  setCartArray(updatedCart);
  localStorage.setItem('cartArray', JSON.stringify(updatedCart));
};

export const deleteCart = (cart, cartArray, setCartArray) => {
  const updatedCartArray = cartArray.filter((item) => item.itemUrl !== cart.itemUrl);
  setCartArray(updatedCartArray);
  localStorage.setItem('cartArray', JSON.stringify(updatedCartArray));
};

export const deleteSelectedCartItems = (cartArray) => {
  // Filter out items where selected is true
  const updatedCartArray = cartArray.filter((item) => !item.selected);
  localStorage.setItem('cartArray', JSON.stringify(updatedCartArray));
};
